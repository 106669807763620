/**
 *
 * @param {string} link - A YouTube video link/url
 */
export const getYouTubeID = link => {
    link = link.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return link[2] !== undefined ? link[2].split(/[^0-9a-z_-]/i)[0] : link[0];
};

/**
 *
 * @param {string} link - A YouTube video link/url
 * @param {string} quality - Choose quality of thumbnail (maxres, hq, mq, sq)
 * @returns {string} video_thumbnail - Image url of video thumbnail
 */
export const getYouTubeThumbnail = (link, quality) => {
    let video_id = getYouTubeID(link);

    let video_thumbnail = `https://img.youtube.com/vi/${video_id}/${quality}default.jpg`;

    return video_thumbnail;
};

/**
 *
 * @param {string} param - URL parameters that you want to get the value.
 * @returns {string} - Value of params that you want to get.
 */
export const getURLParam = param => {
    const qs = window.location.search;
    const url_params = new URLSearchParams(qs);
    const get_params = url_params.get(param) ? url_params.get(param) : "";
    return decodeURIComponent(get_params).replace(/[^\w\s]/gi, "");
};
